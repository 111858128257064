body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body {
   height: 100%;
   width : 100%;
   margin: 0;
   padding: 0;
}

/* .highcharts-container {
	width: 100%;
	position: relative;
} */

.chart {
	width: 50%;
	border: 1px solid red;
	/*margin-left: 1em;*/
}

#header {
	background: #678789;
	margin: 0;
	padding: .5em;
	border: none;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2;
	width: 100%;
}

#header ul {
	padding: 0;
	margin: 0;
}

#header li {
	color: rgb(206, 206, 206);
	display: inline;
	margin: 0;
	padding: 0;
	margin-left: 1em;
	text-decoration: none;
	font-size: 1.2em;
}

#header li:first-child {
	color: rgb(206, 227, 228);
	font-weight: bold;
}

#header li a {
	text-decoration: none;
	color: white;
}

#header li a:hover {
	text-decoration: underline;
	color: white;
}

#header li a:visited {
	color: white;
}

footer {
	background: black;
	color: rgb(204, 204, 204);
	font-size: .8em;
	text-align: center;
	padding: 1em;
}

.content {
	position: relative;
	display: block;
	min-height: 100vh;
	/* max-width: 1229px; */
	padding-top: 3em;
	margin: auto;
	padding-bottom: 3em;
}

.small {
	font-size: .8em;
}
.align-center {
	text-align: center;
}

h1 {
	text-align: center;
	font-size: 3em;
}
h2 {
	text-align: center;
	/* font-size: 3em; */
}

p {
	color: rgb(63, 63, 63);
}

.highcharts-credits {
	display: none;
}

.full {
	max-width: 1000px;
	margin: auto;
}

.errors {
	color: red;
	font-size: 0.6em;
}

.register-cont {
	padding: 2em;
}

.register-cont input{
	width: 300px;
	height: 1.5em;
	padding: .5em;
	font-size: 1em;
}

label {
	color: gray;
}

input[type=submit] {
	background: orange;
	color: white;
	border: none;
	border-radius: 5px;
	padding: .2em;
}

.header-float-right{
	float: right;
}

.logo-image{
	width: 32px;
}

.project-container {
	margin-bottom: 10px;
	background: #eee;
	border: 1px solid orange;
	border-radius: 5px;
	padding-left: 1em;
}

.project-container button{
	margin-bottom: 5px;
	cursor: pointer;
}

.keyword {
	display: inline-block;
	position: relative;
	width: auto;
	margin-bottom: 10px;
	background: #eee;
	border: 1px solid orange;
	border-radius: 5px;
	padding: 5px 10px;
	margin-right: .5em;
	font-size: .7em;
}

.keyword-delete-button {
	margin-left: 5px;
    border-radius: 30px;
    cursor: pointer;
}

.post {
	padding: 2em;
	border-bottom: 1px solid #eee;
	color: gray;
}
